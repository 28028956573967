import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from "react"
import classNames from "classnames"
import debounce from "lodash/debounce"
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftState,
  StateChangeOptions
} from "downshift"
import { Icon } from "../icon/icon"
import { PathPrefix } from "../../core/constants"
import { useSearch } from "../../features/search/hooks/use-search"
import { renderSuggestion, isSearchResult } from "./search-box-helpers"
import { defaultPlaceholder } from "./search-box-constants"
import { Suggestion } from "./search-box-types"
import styles from "./search-box.module.scss"
import { removeOverflow, addOverflowHidden } from "@utils/bodyStyles"

export * from "./search-box-types"

export type SearchBoxVariant = "gray" | "white" | "outline" | "minimal"

export interface SearchBoxProps {
  className?: string
  dropdownClassName?: string
  defaultValue?: string
  placeholder?: string
  variant?: SearchBoxVariant
  highlightFirstSuggestion?: boolean
  hasBodyScrollLock?: boolean
  onKeyDown?: () => void
}

export const SearchBox: FunctionComponent<SearchBoxProps> = ({
  className,
  dropdownClassName,
  defaultValue = "",
  placeholder = defaultPlaceholder,
  variant = "gray",
  hasBodyScrollLock,
  onKeyDown
}) => {
  const {
    suggestions,
    querySuggestions,
    fetchSuggestions,
    searchState,
    clearSuggestions
  } = useSearch()
  const [value, setValue] = useState(defaultValue)
  const [isFocused, setIsFocused] = useState(false)

  // If there's an existing search query in the search state, we want to use it for
  // the input's initial value. However, we can't simply use it as the initial value
  // for useState() above. The value for searchState.configure.query originally comes
  // from a query string param in location.search, which doesn't exist at build time.
  // Pages using this component will be statically generated (i.e. server-side
  // rendered) as if there's no search query/input value - but the value could
  // actually be present client-side during hydration (first render) if the page is
  // loaded with the query string param set. This would result in discrepancies in
  // certain DOM attributes that are influenced by this value (e.g. disabled
  // className of icon), and hydration doesn't patch up these kinds of DOM attribute
  // discrepancies automatically. Therefore, we need to set the search query in state
  // on client-side mount in order to "force" the DOM to properly reflect when a value
  // is present on initial page load.
  useEffect(() => {
    setValue(searchState.configure.query || defaultValue)
  }, [searchState.configure.query, defaultValue])

  // To track whether the keyboard has just been used to highlight a
  // suggestion in the dropdown
  const [arrowKeyUsed, setArrowKeyUsed] = useState(false)

  // fixes debounce issue where results are still rendering after clearing the input
  useEffect(() => {
    if (!value?.length && suggestions?.length) {
      clearSuggestions()
      removeOverflow()
    }
  }, [value, suggestions, clearSuggestions])

  const handleSuggestionsFetchRequested = (query: string): void => {
    if (query && !!query.length) {
      fetchSuggestions(query)
    }
  }

  // https://rajeshnaroth.medium.com/using-throttle-and-debounce-in-a-react-function-component-5489fc3461b3
  // look at this here for the explanation of useRef/useCallback with debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce(handleSuggestionsFetchRequested, 500),
    []
  )

  const handleSelect = (suggestion: any) => {
    if (isSearchResult(suggestion)) {
      setValue("")
    }

    window.location.href = suggestion.url
  }

  const initiateSearch = () => {
    removeOverflow()
    const url = `${PathPrefix.Search}?query=${value}`

    window.location.href = url
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const query = e.target.value

    setValue(query)

    if (query?.length) {
      debouncedFetch(query)
    }
  }

  function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    onKeyDown?.()

    // Pressing Enter when the arrow keys haven't been used to navigate
    // the dropdown items should kick off a search for the input query
    // Downshift's keyDownEnter event doesn't fire in this scenario for
    // some reason (likely due to dropdown not being open?), so we need
    // to do this logic here
    if (e.code === "Enter" && !arrowKeyUsed) {
      initiateSearch()
    }
  }

  // Logic for determining updates to Downshift state based on Downshift
  // events goes here
  function stateReducer(
    state: DownshiftState<Suggestion>,
    changes: StateChangeOptions<Suggestion>
  ) {
    switch (changes.type) {
      // This prevents the menu from being closed when the user clicks
      // back into the search input or presses Escape; also clears any
      // previously-highlighted suggestion in the dropdown
      case Downshift.stateChangeTypes.mouseUp:
      case Downshift.stateChangeTypes.keyDownEscape:
        return {
          ...changes,
          isOpen: state.isOpen,
          highlightedIndex: null
        }
      default:
        return changes
    }
  }

  // Side effects for Downshift events that deal with things
  // *outside of Downshift* go here
  function handleStateChange(
    changes: StateChangeOptions<Suggestion>,
    stateAndHelpers: ControllerStateAndHelpers<Suggestion>
  ) {
    const { isOpen } = stateAndHelpers

    switch (changes.type) {
      // This event *does* fire in this scenario (dropdown is open)
      // Select the highlighted suggestion if arrow keys have been
      // used to highlight it (ignore if mouse has been used to
      // avoid accidental unexpected behavior)
      case Downshift.stateChangeTypes.keyDownEnter:
        if (arrowKeyUsed) {
          handleSelect(changes.selectedItem)
        }

        break
      // Consider arrow keys *not* used whenever the user hovers over
      // suggestions, presses Escape, clicks anywhere (including the
      // input), or types into the input
      // Pressing Enter will not select a dropdown item if done
      // immediately following any of these actions
      case Downshift.stateChangeTypes.itemMouseEnter:
      case Downshift.stateChangeTypes.keyDownEscape:
      case Downshift.stateChangeTypes.mouseUp:
      case Downshift.stateChangeTypes.changeInput:
        setArrowKeyUsed(false)
        break
      // Consider arrow keys to have been used when the corresponding
      // events have fired
      // Pressing enter immediately following these actions *will*
      // select the highlighted suggestion
      case Downshift.stateChangeTypes.keyDownArrowUp:
      case Downshift.stateChangeTypes.keyDownArrowDown:
        setArrowKeyUsed(true)
        break
    }

    if (isOpen && hasBodyScrollLock) {
      addOverflowHidden()
    } else if (!isOpen && hasBodyScrollLock) {
      removeOverflow()
    }
  }

  return (
    <Downshift
      itemToString={(item) => (item ? item.title || "" : "")}
      stateReducer={stateReducer}
      onStateChange={handleStateChange}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
        openMenu,
        closeMenu
      }) => {
        const shouldShowDropdown =
          (querySuggestions.length || suggestions.length) && isOpen

        const renderDropdownSection = (
          items: Suggestion[],
          startingIndex: number
        ) => {
          if (!items.length) return null

          return (
            <ul className={styles.sectionContainer}>
              {items.map((item, index) => {
                // Unique index for Downshift highlighting purposes; accounts
                // for items in previous section of dropdown
                const cumulativeIndex = startingIndex + index

                return (
                  // eslint-disable-next-line react/jsx-key
                  <li
                    {...getItemProps({
                      key: `${item.title}-${cumulativeIndex}`,
                      index: cumulativeIndex,
                      item
                    })}
                  >
                    {renderSuggestion(item, {
                      query: inputValue || "",
                      isHighlighted: highlightedIndex === cumulativeIndex
                    })}
                  </li>
                )
              })}
            </ul>
          )
        }

        return (
          <div
            className={classNames(
              styles.searchBox,
              styles[variant],
              className,
              {
                [styles.focused]: isFocused
              }
            )}
          >
            <div
              {...getRootProps(
                { refKey: "innerRef" },
                { suppressRefError: true }
              )}
              className={styles.inputContainer}
            >
              <input
                {...getInputProps({
                  onChange: handleInputChange,
                  onKeyDown: handleInputKeyDown,
                  onFocus: () => {
                    setIsFocused(true)

                    if (!isOpen && !!suggestions?.length && !!value?.length) {
                      openMenu()
                    }
                  },
                  onBlur: () => {
                    setIsFocused(false)

                    if (isOpen) {
                      closeMenu()
                    }
                  },
                  placeholder,
                  type: "search",
                  value,
                  className: styles.input
                })}
              />
              <Icon
                onClick={initiateSearch}
                className={styles.searchIcon}
                disabled={!value && variant !== "outline"}
                variant="16-search"
              />
            </div>
            {shouldShowDropdown ? (
              <div
                {...getMenuProps({
                  className: classNames(
                    styles.suggestionsContainer,
                    dropdownClassName
                  )
                })}
              >
                {renderDropdownSection(querySuggestions, 0)}
                {renderDropdownSection(suggestions, querySuggestions.length)}
              </div>
            ) : null}
          </div>
        )
      }}
    </Downshift>
  )
}
